import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {appPath} from '../../../app-path.const';
import {CommonService} from "../../../service/common.service";

@Component({
  selector: 'app-open-account',
  templateUrl: './open-account.component.html',
  styleUrls: ['./open-account.component.scss'],
})
export class OpenAccountComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    private common: CommonService
  ) { }

  ngOnInit() {}

  back() {
    this.location.back();
  }

  toVerifyOtp() {
    this.common.presentForwardAlert( '認證碼已發送至您註冊的電子信箱', [appPath.openVerifyOtp]);
  }
}
