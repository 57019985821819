import { Injectable } from '@angular/core';
import {AlertController} from "@ionic/angular";
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public alertController: AlertController,
    private location: Location,
    private router: Router
  ) { }

  async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      cssClass: '',
      header: '訊息',
      subHeader: '',
      // message: '密碼設定成功！<br>請使用新密碼登入',
      message: msg,
      buttons: ['關閉']
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async presentForwardAlert(msg: string, forward: any[]) {
    const alert = await this.alertController.create({
      cssClass: '',
      header: '訊息',
      message: msg,
      buttons: [
        {
          text: '關閉',
          id: 'confirm-button',
          handler: () => {
            this.router.navigate(forward);
          }
        }
      ]
    });

    await alert.present();
  }

  async presentBackAlert(msg: string) {
    const alert = await this.alertController.create({
      cssClass: '',
      header: '訊息',
      message: msg,
      buttons: [
        {
          text: '關閉',
          id: 'confirm-button',
          handler: () => {
            this.location.back();
          }
        }
      ]
    });

    await alert.present();
  }
}
