import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {AlertController} from "@ionic/angular";
import {appPath} from "../../../app-path.const";
import {CommonService} from "../../../service/common.service";

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    private common: CommonService
  ) { }

  ngOnInit() {}

  back() {
    this.location.back();
  }

  save() {
    this.common.presentForwardAlert('密碼設定成功! \n 請使用新密碼登入', [appPath.login]);
  }

}
