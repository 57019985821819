import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {appPath} from '../../../app-path.const';
import {CommonService} from "../../../service/common.service";

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOtpComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    private common: CommonService) { }

  ngOnInit() {}

  back() {
    this.location.back();
  }

  toNewPassword() {
    this.common.presentForwardAlert( '認證成功', [appPath.newPassword]);
  }
}
