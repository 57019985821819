import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './tab1/login/login.component';
import {ForgotPasswordComponent} from './tab1/login/forgot-password/forgot-password.component';
import {NewPasswordComponent} from './tab1/login/new-password/new-password.component';
import {OpenAccountComponent} from './tab1/login/open-account/open-account.component';
import {VerifyOtpComponent} from './tab1/login/verify-otp/verify-otp.component';
import {OpenVerifyOtpComponent} from './tab1/login/open-verify-otp/open-verify-otp.component';
import {
  ChoiceConsumablesComponent
} from './tab2/wo-info/wo-items/consumables/choice-consumables/choice-consumables.component';
import {
  ConsumablesListComponent
} from './tab2/wo-info/wo-items/consumables/choice-consumables/consumables-list/consumables-list.component';
import {UserDocumentComponent} from './tab2/wo-info/wo-items/job-ticket/user-document/user-document.component';
import {UserDataComponent} from './tab2/wo-info/wo-items/job-ticket/user-document/user-data/user-data.component';
import {
  UserSignComponent
} from './tab2/wo-info/wo-items/job-ticket/user-document/user-data/user-sign/user-sign.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'forgotPassword', component: ForgotPasswordComponent},
  { path: 'newPassword', component: NewPasswordComponent},
  { path: 'openAccount', component: OpenAccountComponent},
  { path: 'openVerifyOtp', component: OpenVerifyOtpComponent},
  { path: 'verifyOtp', component: VerifyOtpComponent},
  { path: '', component: LoginComponent},
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)}
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
