import {
  UserSignComponent
} from "./tab2/wo-info/wo-items/job-ticket/user-document/user-data/user-sign/user-sign.component";
import {UserDocumentComponent} from "./tab2/wo-info/wo-items/job-ticket/user-document/user-document.component";
import {UserDataComponent} from "./tab2/wo-info/wo-items/job-ticket/user-document/user-data/user-data.component";
import {
  ConsumablesListComponent
} from "./tab2/wo-info/wo-items/consumables/choice-consumables/consumables-list/consumables-list.component";
import {
  ChoiceConsumablesComponent
} from "./tab2/wo-info/wo-items/consumables/choice-consumables/choice-consumables.component";

export const appPath = {
  //root
  login: '/login',
  forgotPassword: '/forgotPassword',
  newPassword: '/newPassword',
  openAccount: '/openAccount',
  openVerifyOtp: '/openVerifyOtp',
  verifyOtp: '/verifyOtp',
  //tab1
  // login: '/tabs/tab1/login',
  // forgotPassword: '/tabs/tab1/forgotPassword',
  // newPassword: '/tabs/tab1/newPassword',
  // openAccount: '/tabs/tab1/openAccount',
  // verifyOtp: '/tabs/tab1/verifyOtp',
  //tab2
  woInfo: '/tabs/tab2/woInfo',
  woItems: '/tabs/tab2/woItems',
  consumables: '/tabs/tab2/consumables',
  choiceConsumables: '/tabs/tab2/choiceConsumables',
  scanConsumables: '/tabs/tab2/scanConsumables',
  consumablesList: '/tabs/tab2/consumablesList',
  userData: '/tabs/tab2/userData',
  userDocument: '/tabs/tab2/userDocument',
  userPhoto: '/tabs/tab2/userPhoto',
  userSign: '/tabs/tab2/userSign',
  userPreview: '/tabs/tab2/userPreview',
  finishWo: '/tabs/tab2/finishWo',
  jobTicket: '/tabs/tab2/jobTicket',
  payment: '/tabs/tab2/payment',
  creditCard: '/tabs/tab2/creditCard',
  sms: '/tabs/tab2/sms',
  returnWo: '/tabs/tab2/returnWo',
  productDetail: '/tabs/tab2/productDetail',
  woDetail: '/tabs/tab2/woDetail',
  woItemList: '/tabs/tab2/woItemList',
  addDevice: '/tabs/tab2/addDevice',
  changeDevice: '/tabs/tab2/changeDevice',
  provisionDevice: '/tabs/tab2/provisionDevice',
  returnDevice: '/tabs/tab2/returnDevice',
  //tab3
  setting: '/tabs/tab3/setting',
  about: '/tabs/tab3/about',
  aboutDetail: '/tabs/tab3/aboutDetail',
  setupApi: '/tabs/tab3/setupApi',
  //tab4
  user: '/tabs/tab4/user',
  changePassword: '/tabs/tab4/changePassword'
};

